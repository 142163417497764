<template>
  <div>
    <div v-for="review in reviews" :key="review.id" class="row mb-1">
      <div class="col-10">
        {{ review.editedContent ? review.editedContent : review.content }}
        <audio
          v-if="review.editedAudio || review.audio"
          controls
          class="review-audio-inline mr-2"
        >
          <source :src="(review.editedAudio || review.audio).name | blob" />
          Seu navegador não suporta áudio.
        </audio>
        <a
          v-if="review.editedYoutubeId || review.youtubeId"
          target="_blank"
          :href="`https://www.youtube.com/watch?v=${
            review.editedYoutubeId || review.youtubeId
          }`"
          class="mr-1"
        >
          <i class="mdi mdi-youtube"></i>
        </a>

        <span>- @{{ review.user.username }}</span>
      </div>

      <div
        v-if="reactToReview && review.user_id !== currentUser.id"
        class="col-2 text-right"
      >
        <i
          title="Positivo"
          :class="{ 'text-info': review.isLike }"
          @click.prevent="reactToReview(review, true)"
          class="mdi mdi-thumb-up mdi-18px mr-2"
          style="cursor: pointer;"
        ></i>

        <i
          title="Negativo"
          :class="{ 'text-danger': review.isLike === false }"
          @click.prevent="reactToReview(review, false)"
          class="mdi mdi-thumb-down mdi-18px mr-2"
          style="cursor: pointer;"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ReviewsNotebook",

  props: {
    reviews: {
      type: Array,
      default: () => [],
    },

    reactToReview: {
      type: Function,
    },
  },

  computed: {
    ...mapState("auth", ["currentUser"]),
  },
};
</script>

<style scoped>
.review-audio-inline {
  height: 20px;
  top: 5px;
  position: relative;
}
</style>
