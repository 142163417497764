<template>
  <div>
    <div class="card mb-4">
      <div class="card-body text-dark">
        <div class="container my-2">
          <template v-if="!question.isReport">
            <div class="row mb-4">
              <div class="col-md-3 col-12">
                <h5>Ano: {{ question.year }}</h5>
              </div>
              <div class="col-md-3 col-12">
                <h5>Banca: {{ question.jury }}</h5>
              </div>
              <div class="col-md-3 col-12">
                <h5>Órgão: {{ question.institute }}</h5>
              </div>
              <div class="col-md-3 col-12">
                <h5>Cargo: {{ question.role }}</h5>
              </div>
              <div class="col-md-3 col-12">
                <h5>Disciplina: {{ question.discipline }}</h5>
              </div>
              <div class="col-md-9 col-12">
                <h5>
                  Assuntos:
                  {{ question.subjects.map((s) => s.name).join(", ") }}
                </h5>
              </div>
            </div>
          </template>

          <template v-else>
            <div class="row mb-4">
              <div class="col-md-6 col-12">
                <h5>Número: {{ question.number }}</h5>
              </div>
              <div class="col-md-6 col-12">
                <h5>Origem: {{ question.source }}</h5>
              </div>
              <div class="col-md-12 col-12">
                <h5>Título: {{ question.title }}</h5>
              </div>
            </div>
          </template>

          <div v-if="!question.isReport" class="mb-3">
            <h3 class="question-question-container" v-html="question.question"></h3>
          </div>

          <div v-if="question.isReport" class="mb-3">
            <div class="question-question-container" v-html="question.question"></div>
          </div>

          <form @submit.prevent="onSubmit">
            <div class="mb-3">
              <b-form-group>
                <b-form-radio-group
                  v-model="form.selected"
                  buttons
                  button-variant="outline-v2-dark"
                  stacked
                  class="mx-1"
                  size="lg"
                  style="
                    border-radius: 0px !important;
                    width: 100% !important;
                    display: block;
                  "
                >
                  <template v-for="(answer, i) in answers">
                    <span
                      class="question-form"
                      :key="i"
                      style="display: flex; appearance: none;"
                    >
                      <b-form-radio
                        :value="answer.value"
                        class="text-left my-2"
                        style="border-radius: 4px !important; flex: 1;"
                        :disabled="isAnswered || answer.effect === 'strike'"
                        :class="[
                          {
                            'bg-white text-danger border border-danger':
                              isAnswered &&
                              question.realAnswer !== question.answer &&
                              answer.value === question.answer,
                          },
                          {
                            'bg-white text-success border border-success':
                              isAnswered &&
                              question.realAnswer === answer.value,
                          },
                          {
                            'bg-white thinking-border':
                              answer.effect === 'thinking' &&
                              form.selected !== answer.value &&
                              !isAnswered,
                          },
                          {
                            'bg-white strike-border':
                              answer.effect === 'strike' &&
                              form.selected !== answer.value &&
                              !isAnswered,
                          },
                        ]"
                      >
                        <div style="display: inline-grid !important;">
                          <span
                            v-if="answers.length > 2"
                            class="mb-1"
                            style="font-weight: 700; text-transform: uppercase;"
                          >
                            {{ answer.value }}
                          </span>
                          <span
                            :class="{
                              'line-strike': answer.effect === 'strike',
                              'mt-2': answers.length <= 2
                            }"
                          >
                            {{ answer.text }}
                          </span>
                        </div>
                      </b-form-radio>
                      <div
                        v-if="!isAnswered"
                        style="flex-direction: column; margin-left: 8px;"
                        class="justify-content-center d-flex"
                      >
                        <button
                          v-if="!isAnswered"
                          @click.prevent="setEffect(answer, 'strike')"
                          class="btn btn-danger rounded-circle mb-1"
                          v-b-tooltip.hover.right="'Parece errado'"
                        >
                          <i class="mdi mdi-content-cut"></i>
                        </button>
                        <button
                          v-if="!isAnswered"
                          @click.prevent="setEffect(answer, 'thinking')"
                          class="btn btn-info rounded-circle"
                          v-b-tooltip.hover.right="'Parece certo'"
                        >
                          <i class="mdi mdi-brain"></i>
                        </button>
                      </div>
                    </span>
                  </template>
                </b-form-radio-group>
              </b-form-group>
            </div>

            <div>
              <button
                v-if="!question.isReport && !isAnswered"
                type="submit"
                class="btn btn-lg btn-primary mx-auto d-block mb-3"
              >
                <i class="mdi mdi-check-bold"></i>
                Responder
              </button>

              <div ref="result" class="row">
                <div v-if="question.isReport" class="col-md-6 col-12"></div>
                <div v-if="!question.isReport" class="col-md-6 col-12">
                  <template v-if="isAnswered">
                    <b-alert
                      v-if="isCorrect"
                      show
                      class="bg-success text-white border-0"
                    >
                      <h4 class="mb-2">
                        Alternativa correta!
                      </h4>
                      <p>
                        A melhor forma de fixar o conhecimento é produzir
                        comentários sobre cada uma das alternativas da questão.
                        Experimente! Clique em "Analisar alternativas da
                        questão" e envie-nos a sua contribuição em forma de
                        texto, áudio e/ou vídeo.
                      </p>
                      <button
                        v-if="question.isReport || isAnswered"
                        :disabled="showReviews"
                        @click.prevent="openReviews()"
                        class="btn btn-info btn-block my-1"
                      >
                        <i class="mdi mdi-notebook-outline"></i>
                        {{
                          question.isReport
                            ? "Análise"
                            : "Analisar as alternativas da questão"
                        }}
                      </button>
                    </b-alert>

                    <b-alert
                      v-if="!isCorrect"
                      show
                      class="bg-danger text-white border-0"
                    >
                      <h4 class="mb-2">
                        Alternativa incorreta!
                      </h4>
                      <h5 class="mb-2">
                        Gabarito:
                        <strong class="text-uppercase">{{
                          question.realAnswer
                        }}</strong>
                      </h5>
                      <p>
                        Uma forma interessante de evitar novos erros é estudar
                        cuidadosamente as alternativas e produzir comentários
                        sobre cada uma delas. Experimente! Clique em "Analisar
                        as alternativas da questão" e envie-nos sua contribuição
                        em forma de texto, áudio e/ou vídeo.
                      </p>
                      <button
                        v-if="question.isReport || isAnswered"
                        :disabled="showReviews"
                        @click.prevent="openReviews()"
                        class="btn btn-info btn-block my-1"
                      >
                        <i class="mdi mdi-notebook-outline"></i>
                        {{
                          question.isReport
                            ? "Análise"
                            : "Analisar as alternativas da questão"
                        }}
                      </button>
                    </b-alert>
                  </template>
                </div>

                <div class="col-md-6 col-12">
                  <div class="float-right text-right my-2">
                    <div class="mb-2">
                      <button
                        v-if="
                          !question.isReport &&
                          isAnswered &&
                          !answeredHere &&
                          !stats
                        "
                        @click.prevent="loadStats"
                        class="btn btn-outline-info my-1"
                      >
                        <i class="mdi mdi-chart-pie"></i>
                        Estatística
                      </button>
                      <button
                        v-if="question.isReport || isAnswered"
                        :hide="showReviews"
                        @click.prevent="openReviews()"
                        class="btn btn-outline-info my-1 ml-1"
                      >
                        <i class="mdi mdi-notebook-outline"></i>
                        {{
                          question.isReport
                            ? "Análise"
                            : "Análise da resposta correta"
                        }}
                      </button>
                    </div>

                    <div class="float-md-right mb-2">
                      <router-link
                        v-if="isAnswered && !question.isReport"
                        tag="a"
                        :to="{
                          name: 'selectMentor',
                          query: {
                            questionId: question.id,
                          },
                        }"
                        target="_blank"
                        class="btn btn-outline-warning ml-1"
                      >
                        <i class="mdi mdi-help-circle"></i>
                        Quero ajuda do professor!
                      </router-link>

                      <button
                        @click.prevent="reportError(question)"
                        class="btn btn-outline-warning ml-2"
                      >
                        <i class="mdi mdi-flag"></i>
                        Reportar problema
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div ref="stats" v-if="isAnswered" class="mb-3">
            <QuestionStats v-if="stats" :stats="stats" />
          </div>
        </div>

        <div class="mb-4 text-right">
          <b-button
            pill
            variant="dark"
            class="mr-1"
            @click.prevent="copySocialUrl"
            ><i class="mdi mdi-share-variant mdi-18px"></i
          ></b-button>

          <ShareNetwork
            v-for="network in ['facebook', 'twitter', 'whatsapp', 'telegram']"
            :key="network"
            :network="network"
            :url="socialShare.url"
            :title="socialShare.title"
            :description="socialShare.description"
            :quote="socialShare.quote"
            :hashtags="socialShare.hashtags"
          >
            <b-button pill variant="dark" class="mr-1"
              ><i :class="`mdi mdi-${network} mdi-18px`"></i
            ></b-button>
          </ShareNetwork>
          <!-- <b-button
            pill
            variant="dark"
            class="mr-1"
            v-b-modal="`instagram-modal-${question.id}`"
            ><i class="mdi mdi mdi-instagram mdi-18px"></i
          ></b-button> -->
        </div>
      </div>
    </div>

    <div ref="reviews" v-if="showReviews" class="card">
      <div class="card-header bg-light">
        <h4>Análise final da resposta correta</h4>
      </div>
      <div class="card-body" v-html="finalReview"></div>

      <div v-if="audios.length" class="p-2">
        <audio
          v-for="audio in audios"
          :key="audio.id"
          controls
          class="review-audio"
        >
          <source :src="audio.name | blob" />
          Seu navegador não suporta áudio.
        </audio>
      </div>

      <div v-if="videos.length" class="p-2">
        <iframe
          v-for="(video, i) in videos"
          :key="i"
          controls
          class="review-video mt-2"
          width="560"
          height="315"
          :src="`https://www.youtube.com/embed/${video}`"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>

    <div ref="reviews" v-if="showReviews" class="card">
      <div class="card-header bg-light">
        <h4>Minha análise da resposta correta</h4>
      </div>
      <div class="card-body">
        <Reviews
          ref="reviews"
          :question="question"
          @loadedReviews="(rs) => (reviews = rs)"
        />
      </div>
    </div>

    <b-modal
      :id="`instagram-modal-${question.id}`"
      size="xl"
      title="Compartilhar"
      body-class="text-center"
      ok-only
    >
      <ShareableImage
        :headerText="shareImageData.headerText"
        :middleText="shareImageData.middleText"
        :footerText="shareImageData.footerText"
        :urlText="shareImageData.urlText"
        :background="shareImageData.background"
      />
    </b-modal>
  </div>
</template>

<script>
import ShareableImage from "@/components/ShareableImage";
import QuestionStats from "@/components/QuestionStats";
import Reviews from "@/components/Reviews";
import Swal from "sweetalert2";
import stripHtml from "string-strip-html";
import * as api from "@/api";

export default {
  name: "Question",

  components: {
    ShareableImage,
    QuestionStats,
    Reviews,
  },

  props: {
    question: {
      type: Object,
    },

    showAccessButton: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    if (this.$route.meta.isQuestionPage && this.isAnswered) {
      this.showReviews = true;
    }
  },

  data() {
    return {
      form: {
        selected: "",
      },

      answeredHere: false,
      stats: null,
      showReviews: false,
      reviews: [],
      currentEffect: {
        a: null,
        b: null,
        c: null,
        d: null,
        e: null,
      },
    };
  },

  computed: {
    shareImageData() {
      if (!this.isAnswered) {
        return {
          urlText: this.socialUrl,
        };
      }

      if (this.isCorrect) {
        return {
          headerText: "QuestõesPRO",
          middleText: "Parabéns, você acertou!",
          footerText: "Você acertou a questão",
          urlText: this.socialUrl,
          background: "success",
        };
      }

      return {
        headerText: "QuestõesPRO",
        middleText: "Errou no treino, mas acertará na prova!",
        footerText: "Estude mais para resolver esta questão",
        urlText: this.socialUrl,
        background: "danger",
      };
    },

    answers() {
      if (!this.question) {
        return [];
      }
      return [
        {
          value: "a",
          text: `${this.question.answerA}`,
          effect: this.currentEffect.a,
        },
        {
          value: "b",
          text: `${this.question.answerB}`,
          effect: this.currentEffect.b,
        },
        {
          value: "c",
          text: `${this.question.answerC}`,
          effect: this.currentEffect.c,
        },
        {
          value: "d",
          text: `${this.question.answerD}`,
          effect: this.currentEffect.d,
        },
        {
          value: "e",
          text: `${this.question.answerE}`,
          effect: this.currentEffect.e,
        },
      ].filter(({ text }) => text.length);
    },

    isAnswered() {
      return Boolean(this.question && this.question.realAnswer);
    },

    isCorrect() {
      return (
        this.isAnswered && this.question.realAnswer === this.question.answer
      );
    },

    audios() {
      return this.reviews
        .filter((r) => r.active && r.audio)
        .map((r) => r.audio);
    },

    videos() {
      return this.reviews
        .filter((r) => r.active && r.youtubeId)
        .map((r) => r.youtubeId);
    },

    socialUrl() {
      const route = this.$router.resolve({
        name: "viewQuestion",
        params: {
          id: this.question.id,
        },
      });

      if (!route) {
        return;
      }

      return window.location.origin + route.href;
    },

    socialShare() {
      if (!this.question) {
        return;
      }

      const baseTitle = stripHtml(this.question.question).result;
      const title =
        baseTitle.length > 70 ? `${baseTitle.slice(0, 66)}...?` : baseTitle;

      const description = "Estou resolvendo desafios no QuestõesPRO, vem ver!";

      return {
        url: this.socialUrl,

        // Twitter, WhatsApp
        title,

        // WhatsApp
        description,

        // Facebook
        quote: description,

        // Facebook, Twitter
        hashtags: "questoespro",
      };
    },

    finalReview() {
      return this.question.finalReview.replace(/\n/g, "<br />");
    },
  },

  methods: {
    loadStats() {
      return api
        .getQuestionStatsById(this.question.id)
        .then((stats) => {
          this.stats = stats;
        })
        .catch(() => {
          this.$swal("Opa", "Algo deu errado", "warning");
        });
    },

    onSubmit() {
      if (!this.form.selected) {
        return this.$swal(
          "Opa",
          "Você precisa escolher uma alternativa",
          "warning"
        );
      }

      this.answeredHere = true;

      api
        .answerQuestion(this.question.id, this.form.selected)
        .then((question) => {
          Object.assign(this.question, question);
          this.loadStats();
          if (this.$refs.reviews) {
            this.$refs.reviews.loadReviews();
          }
          this.scrollToResults();
        })
        .catch(() => {
          this.$swal("Opa", "Algo deu errado", "warning");
        });
    },

    copySocialUrl() {
      const $el = document.createElement("textarea");
      $el.value = this.socialUrl;
      document.body.appendChild($el);
      $el.select();
      document.execCommand("copy");
      document.body.removeChild($el);

      this.$bvToast.toast(
        `Você pode colar o link da pergunta em qualquer lugar`,
        {
          title: "Link copiado",
          autoHideDelay: 3000,
        }
      );
    },

    setEffect(answer, effect) {
      if (this.currentEffect[answer.value] === effect) {
        this.currentEffect[answer.value] = null;
        return;
      }

      this.currentEffect[answer.value] = effect;

      if (effect === "strike" && this.form.selected === answer.value) {
        this.form.selected = "";
      }
    },

    reportError(question) {
      Swal.fire({
        title: "Reportar erro",
        input: "textarea",
        showCloseButton: true,
        confirmButtonText: "Reportar",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#38a4f8",
        allowOutsideClick: false,
      }).then(({ isDismissed, value: report }) => {
        if (isDismissed || !report || !report.length) {
          return;
        }
        this.doReportError(question, report);
      });
    },

    doReportError(question, report) {
      api
        .reportError(question.id, report)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Erro reportado com sucesso!",
          });
        })
        .catch((err) => {
          if (err) {
            this.$swal("Opa", "Falha ao reportar erro!", "error");
          } else {
            this.$swal.stopLoading();
            this.$swal.close();
          }
        });
    },

    openReviews() {
      this.showReviews = true;

      setTimeout(() => {
        const $el = this.$refs.reviews;
        window.scrollTo(
          0,
          window.pageYOffset + $el.getBoundingClientRect().top - 80 - 54
        );
      }, 1000);
    },

    scrollToResults() {
      setTimeout(() => {
        const $el = this.$refs.result;
        window.scrollTo(
          0,
          window.pageYOffset + $el.getBoundingClientRect().top - 80 - 75
        );
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.question-form {
  input[type="radio"] {
    display: none;
  }
}

.line-strike {
  text-decoration: line-through;
}

.thinking-border {
  border: 3px solid #38a4f8 !important;
  color: #5b626b !important;
}

.strike-border {
  border: 3px solid red !important;
  color: #5b626b !important;
}

.ql-size-small {
  font-size: small;
}

.ql-size-large {
  font-size: large;
}

.ql-size-huge {
  font-size: x-large;
}

/* hack because google spreadsheets add this weird hardcoded width */
.question-question-container .softmerge-inner {
  width: unset!important;
}
</style>
