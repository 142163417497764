<template>
  <div>
    <div v-if="!reviews.length">
      <b-alert show variant="dark">
        Nenhuma análise. Seja o primeiro a contribuir e receba bonificação!
      </b-alert>
    </div>

    <div v-if="!editMode">
      <ReviewsNotebook :reviews="reviews" :reactToReview="reactToReview" />

      <div class="mt-4">
        <b-button
          size="sm"
          variant="primary"
          @click.prevent="editMode = true"
          style="margin-right: 5px;"
        >
          Modo de edição
        </b-button>
      </div>
    </div>

    <div v-else>
      <div v-if="reviews.some((r) => r.isDirty || !r.active)" class="mb-2">
        <span class="mr-2">Legenda de barras laterais:</span>
        <b-badge variant="success" class="mr-2">Publicado</b-badge>
        <b-badge variant="light" class="mr-2">Não salvo</b-badge>
        <b-badge variant="warning" class="mr-2">Aguardando aprovação</b-badge>
        <b-badge variant="danger" class="mr-2">Precisa melhorar</b-badge>
      </div>

      <div v-for="review in myReviews" :key="review.id">
        <div class="ml-2">
          <div class="row">
            <div class="col-12 pl-0 pr-0">
              <blockquote
                class="blockquote blockquote-review font-16"
                :class="{
                  ['blockquote-review-chages-requested']:
                    review.requestedChanges &&
                    review.user.id === currentUser.id,
                  ['blockquote-review-edited']: review.edited && review.isDirty,
                  ['blockquote-review-pending']:
                    review.edited && review.user.id === currentUser.id,
                }"
              >
                <template>
                  <QuillEditor
                    v-if="review.user.id === currentUser.id"
                    id="question"
                    :options="{
                      placeholder: 'Insira o texto aqui...',
                      modules: {
                        toolbar: [['bold', 'italic', 'underline']],
                      },
                    }"
                    v-model="review.content"
                    @input="() => changedReview(review)"
                    required
                  ></QuillEditor>

                  <contenteditable v-else tag="p" />
                </template>
                <VueRecordAudio
                  v-if="review.user.id === currentUser.id"
                  class="audio-recorder ml-2"
                  mode="press"
                  @result="(file) => onAudio(review, file)"
                />
                <div
                  v-if="review.user.id === currentUser.id"
                  class="youtube-video-button ml-2"
                  @click="onYoutubeButtonClicked(review)"
                >
                  <i class="mdi mdi-youtube"></i>
                </div>

                <footer class="blockquote-footer ml-2">
                  @{{ review.user.username }}
                  <b-button
                    v-if="review.audio && review.user.id === currentUser.id"
                    variant="danger"
                    size="sm"
                    @click="tryToDeleteReviewAudio(review)"
                  >
                    Remover audio
                  </b-button>
                </footer>

                <div></div>

                <audio v-if="review.audio" controls class="review-audio">
                  <source :src="review.audio.name | blob" />
                  Seu navegador não suporta áudio.
                </audio>

                <div></div>

                <iframe
                  v-if="review.youtubeId"
                  width="560"
                  height="315"
                  :src="`https://www.youtube.com/embed/${review.youtubeId}`"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  class="mt-2"
                ></iframe>
              </blockquote>
            </div>
          </div>
        </div>

        <div
          v-if="review.requestedChanges && review.user.id === currentUser.id"
        >
          <ul class="conversation-list">
            <li class="clearfix">
              <div class="chat-avatar">
                <img
                  :src="$defaultUserAvatar"
                  alt="male"
                  class="avatar-xs rounded-circle"
                />
              </div>
              <div class="conversation-text">
                <div class="ctext-wrap">
                  <span class="user-name">Revisor</span>
                  <p>{{ review.requestedChanges }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="mt-4">
        <b-button
          v-show="isDirty"
          :disabled="!isDirty"
          size="sm"
          variant="success"
          @click.prevent="saveReviews"
          style="margin-right: 5px;"
        >
          Salvar
        </b-button>

        <b-button
          :disabled="isDirty"
          size="sm"
          variant="primary"
          @click.prevent="addReview"
        >
          Adicionar análise
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/api";
import { mapState } from "vuex";
import ReviewsNotebook from "@/components/ReviewsNotebook";
import { quillEditor } from "vue-quill-editor";

function coalesce(...xs) {
  return xs.find((x) => x);
}

export default {
  name: "Reviews",

  components: {
    ReviewsNotebook,
    QuillEditor: quillEditor,
  },

  props: ["question"],

  data() {
    return {
      reviews: [],
      editMode: true,
    };
  },

  created() {
    this.loadReviews();
  },

  computed: {
    ...mapState("auth", ["currentUser"]),

    myReviews() {
      return this.reviews.filter((r) => r.user_id === this.currentUser.id);
    },

    isDirty() {
      return !this.savedChanges;
    },

    savedChanges() {
      return this.reviewsToSave.length === 0;
    },

    reviewsToSave() {
      return this.reviews.filter(
        (review) => review.user.id === this.currentUser.id && review.isDirty
      );
    },
  },

  methods: {
    loadReviews() {
      api
        .getQuestionReviews(this.question.id)
        .then((reviews) => {
          this.reviews = reviews.map((review) => ({
            ...review,
            edited:
              Boolean(review.editedContent) ||
              Boolean(review.editedAudioBlobId) ||
              Boolean(review.editedYoutubeId),
            isDirty: false,
            content: coalesce(review.editedContent, review.content),
            audioBlobId: coalesce(review.editedAudioBlobId, review.audioBlobId),
            audio: coalesce(review.editedAudio, review.audio),
            youtubeId: coalesce(review.editedYoutubeId, review.youtubeId),
          }));
          this.$emit("loadedReviews", this.reviews);
        })
        .catch(() => {
          this.$swal(
            "Opa",
            "Algo deu errado carregando as revisões",
            "warning"
          );
        });
    },

    changedReview(review) {
      // Edited could be from server editions or local
      review.edited = true;
      // Only true if edited locally
      review.isDirty = true;
    },

    addReview() {
      this.reviews.push({
        id: new Date().getTime(),
        audioBlobId: null,
        content: "",
        editedContent: "",
        user_id: this.currentUser.id,
        user: this.currentUser,
        active: false,
        edited: true,
        isDirty: true,
        isNew: true,
      });
    },

    async saveReviews() {
      // This needs to be sequential!
      for (let review of this.reviewsToSave) {
        await (review.isNew
          ? api.reviewQuestion(this.question.id, {
              editedContent: review.content,
              editedAudioBlobId: review.audioBlobId,
              editedYoutubeId: review.youtubeId,
            })
          : api.updateReview(this.question.id, review.id, {
              audioBlobId: review.audioBlobId === null ? null : undefined,
              editedContent: review.content,
              editedAudioBlobId: review.audioBlobId,
              editedYoutubeId: review.youtubeId,
            })
        )
          .then((updated) => {
            this.$set(this.reviews, this.reviews.indexOf(review), {
              ...updated,
              isNew: false,
              isDirty: false,
              edited: Boolean(updated.editedContent),
              content: coalesce(updated.editedContent, updated.content),
              audioBlobId: coalesce(
                updated.editedAudioBlobId,
                updated.audioBlobId
              ),
              audio: coalesce(updated.editedAudio, updated.audio),
              youtubeId: coalesce(updated.editedYoutubeId, updated.youtubeId),
            });
          })
          .catch(() => {
            this.$swal(
              "Opa",
              "Algo deu errado salvando uma revisão",
              "warning"
            );
          });
      }
    },

    onAudio(review, file) {
      api
        .uploadFile(file, "audio")
        .then((audio) => {
          review.audio = audio;
          review.audioBlobId = audio.id;
          this.changedReview(review);
        })
        .catch(() => {
          this.$swal("Opa", "Algo deu errado salvando o áudio", "warning");
        });
    },

    tryToDeleteReviewAudio(review) {
      this.$swal({
        title: "Você tem certeza?",
        text: "Esta ação não pode ser revertida!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#02a499",
        cancelButtonColor: "#ec4561",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, deletar!",
      }).then((result) => {
        if (!result.value) {
          return;
        }

        review.audio = null;
        review.audioBlobId = null;
        this.changedReview(review);
      });
    },

    onYoutubeButtonClicked(review) {
      this.$swal({
        title: "Atualizando vídeo",
        text: "Deixe vazio para remover",
        input: "text",
        inputValue:
          review.youtubeId && review.youtubeId.length
            ? `https://youtu.be/${review.youtubeId}`
            : undefined,
        buttons: ["cancel", "confirm"],
        showCancelButton: true,
        confirmButtonColor: "#02a499",
        cancelButtonColor: "#ec4561",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Atualizar",
      }).then((result) => {
        if (!result.isConfirmed) {
          return;
        }

        if (result.value.length === 0) {
          this.$set(this.reviews, this.reviews.indexOf(review), {
            ...review,
            youtubeId: "",
          });
        } else {
          const parsedId = parseYoutubeId(result.value);

          if (!parsedId) {
            this.$swal("URL inválido!", "Tente novamente", "error");
            return;
          }
          this.$set(this.reviews, this.reviews.indexOf(review), {
            ...review,
            youtubeId: parsedId,
          });
          this.changedReview(review);
        }
      });
    },

    reactToReview(review, isLike) {
      const update = (value) =>
        this.$set(this.reviews, this.reviews.indexOf(review), {
          ...review,
          isLike: value,
        });

      update(isLike);
      api.reactToReview(this.question.id, review.id, isLike).catch(() => {
        update(!isLike);
        this.$swal("Opa", "Algo deu errado em sua reação", "warning");
      });
    },
  },
};

function parseYoutubeId(url) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}
</script>

<style>
.blockquote-review {
  padding-top: 0;
  padding-bottom: 0;
  border-left-color: #02a499;
}

.blockquote-review > p {
  width: 100%;
}

.blockquote-review-pending {
  border-left-color: #f8b425;
}
.blockquote-review-edited {
  border-left-color: #f8f9fa;
}
.blockquote-review-chages-requested {
  border-left-color: #ec4561;
}

.blockquote-review > p {
  margin-bottom: 0;
}

.blockquote-review > p,
.blockquote-review .blockquote-footer {
  display: inline-block;
}

.audio-recorder {
  height: 32px;
  width: 32px;
  margin-right: 5px;
  margin-left: 0px !important;
  position: relative;
  top: 12px;
}

.review-audio {
  display: block;
  margin-top: 15px;
}

.youtube-video-button {
  position: relative;
  background-color: #e52d27;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: inline-block;
  cursor: pointer;
  color: white;
  text-align: center;
}
.youtube-video-button i {
  position: relative;
  top: 3px;
}
.ql-editor strong {
  font-weight: 700;
}
</style>
