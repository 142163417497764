<template>
  <div>
    <h3>Estatística</h3>
    <h5>Porcentagem global de acertos e erros da pergunta</h5>
    <div class="row">
      <div class="col-md-6">
        <apexchart
          class="apex-charts"
          height="320"
          type="pie"
          :series="[
            questionPercentageStats.correctPerc,
            questionPercentageStats.wrongPerc,
          ]"
          :options="{
            dataLabels: {
              enabled: true,
              formatter(val) {
                return Math.floor(val) + '%';
              },
            },
            labels: ['Acertos', 'Erros'],
            colors: ['#02a499', '#ec4561'],
            legend: {
              show: true,
              position: 'bottom',
              horizontalAlign: 'center',
              verticalAlign: 'middle',
              floating: false,
              fontSize: '14px',
              offsetX: 0,
              offsetY: -5,
            },
            tooltip: {
              y: {
                formatter(val) {
                  return Math.floor(val) + '%';
                },
              },
            },
            responsive: [
              {
                breakpoint: 600,
                options: {
                  chart: {
                    height: 250,
                  },
                  legend: {
                    show: false,
                  },
                },
              },
            ],
          }"
        ></apexchart>
      </div>
      <div class="col-md-6">
        <div class="row mt-3">
          <div class="col-md-12 col-4 text-center">
            <h4 class="text-center mb-0">
              {{ this.stats.totalAnswers }}
            </h4>
            <p class="text-muted">Total</p>
          </div>
          <div class="col-md-12 col-4 text-center">
            <h4 class="text-center mb-0">
              {{ this.stats.correctAnswers }}
            </h4>
            <p class="text-muted">Acertos</p>
          </div>
          <div class="col-md-12 col-4 text-center">
            <h4 class="mb-0">
              {{ this.stats.totalAnswers - this.stats.correctAnswers }}
            </h4>
            <p class="text-muted">Erros</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionStats",

  components: {},

  props: {
    stats: Object,
  },

  data() {
    return {};
  },

  computed: {
    questionPercentageStats() {
      const total = Number(this.stats.totalAnswers);
      const correct = Number(this.stats.correctAnswers);
      const wrong = total - correct;
      const correctPerc = (correct / total) * 100;
      const wrongPerc = (wrong / total) * 100;
      return { correctPerc, wrongPerc };
    },
  },
};
</script>
