var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("Estatística")]),_c('h5',[_vm._v("Porcentagem global de acertos e erros da pergunta")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('apexchart',{staticClass:"apex-charts",attrs:{"height":"320","type":"pie","series":[
          _vm.questionPercentageStats.correctPerc,
          _vm.questionPercentageStats.wrongPerc,
        ],"options":{
          dataLabels: {
            enabled: true,
            formatter(val) {
              return Math.floor(val) + '%';
            },
          },
          labels: ['Acertos', 'Erros'],
          colors: ['#02a499', '#ec4561'],
          legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0,
            offsetY: -5,
          },
          tooltip: {
            y: {
              formatter(val) {
                return Math.floor(val) + '%';
              },
            },
          },
          responsive: [
            {
              breakpoint: 600,
              options: {
                chart: {
                  height: 250,
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
        }}})],1),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-12 col-4 text-center"},[_c('h4',{staticClass:"text-center mb-0"},[_vm._v(" "+_vm._s(this.stats.totalAnswers)+" ")]),_c('p',{staticClass:"text-muted"},[_vm._v("Total")])]),_c('div',{staticClass:"col-md-12 col-4 text-center"},[_c('h4',{staticClass:"text-center mb-0"},[_vm._v(" "+_vm._s(this.stats.correctAnswers)+" ")]),_c('p',{staticClass:"text-muted"},[_vm._v("Acertos")])]),_c('div',{staticClass:"col-md-12 col-4 text-center"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(this.stats.totalAnswers - this.stats.correctAnswers)+" ")]),_c('p',{staticClass:"text-muted"},[_vm._v("Erros")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }