<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-6">
        <div class="page-title-box">
          <h3>
            {{ isReport ? "Informativos de Jurisprudência" : "Questões" }}
          </h3>
        </div>
      </div>
    </div>

    <b-alert
      v-if="!isReport && !currentUser.interests.length"
      show
      variant="warning"
    >
      <div class="d-flex justify-content-between">
        <strong class="py-2">
          Você não escolheu seus interesses ainda!<br />
        </strong>
        <div>
          <router-link
            class="btn btn-success text-right"
            :to="{ name: 'myInterests' }"
          >
            Escolher
          </router-link>
        </div>
      </div>
    </b-alert>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-12">
        <div class="card card-body">
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="mdi mdi-magnify"></i>
              </span>
            </div>
            <input
              type="text"
              v-model="search"
              class="form-control"
              placeholder="Pesquisar..."
              @input="loadQuestions"
            />
          </div>

          <template v-if="!isReport">
            <b-form-checkbox
              v-if="currentUser.interests.length"
              v-model="onlyMyInterests"
              switch
              class="mb-2"
            >
              Apenas os meus interesses
            </b-form-checkbox>

            <select
              v-if="!currentUser.interests.length || !onlyMyInterests"
              v-model="selectedInterestId"
              class="form-control mb-2"
              @change="loadQuestions"
            >
              <option value="">Todos os interesses</option>
              <option
                v-for="interest in interests"
                :key="interest.id"
                :value="interest.id"
              >
                {{ interest.name }}
              </option>
            </select>
            <select
              v-model="roles"
              class="form-control mb-2"
              @change="loadQuestions"
            >
              <option value="" selected>Todos os cargos</option>
              <option
                v-for="role in formOptions.role"
                :key="role"
                :value="role"
              >
                {{ role }}
              </option>
            </select>
            <select
              v-model="institutes"
              class="form-control mb-2"
              @change="loadQuestions"
            >
              <option value="" selected>Todos os órgãos</option>
              <option
                v-for="institute in formOptions.institute"
                :key="institute"
                :value="institute"
              >
                {{ institute }}
              </option>
            </select>
            <select
              v-model="years"
              class="form-control mb-2"
              @change="loadQuestions"
            >
              <option value="" selected>Todos os anos</option>
              <option
                v-for="year in formOptions.year"
                :key="year"
                :value="year"
              >
                {{ year }}
              </option>
            </select>
            <select
              v-model="disciplines"
              class="form-control mb-2"
              @change="loadQuestions"
            >
              <option value="" selected>Todas as disciplinas</option>
              <option
                v-for="discipline in formOptions.discipline"
                :key="discipline"
                :value="discipline"
              >
                {{ discipline }}
              </option>
            </select>
            <select
              v-model="juries"
              class="form-control mb-2"
              @change="loadQuestions"
            >
              <option value="" selected>Todas as bancas</option>
              <option
                v-for="jury in formOptions.jury"
                :key="jury"
                :value="jury"
              >
                {{ jury }}
              </option>
            </select>
            <select
              v-model="subjectId"
              class="form-control mb-2"
              @change="loadQuestions"
            >
              <option value="">Todos os assuntos</option>
              <option
                v-for="subject in formOptions.subject"
                :key="subject.id"
                :value="subject.id"
              >
                {{ subject.name }}
              </option>
            </select>
            <select
              v-model="mode"
              class="form-control mb-2"
              @change="loadQuestions"
            >
              <option value="" selected>Todas as modalidades</option>
              <option
                v-for="mode in [
                  {
                    key: 'multiple',
                    label: 'Múltipla escolha',
                  },
                  {
                    key: 'trueOrFalse',
                    label: 'Certo ou errado',
                  },
                ]"
                :key="mode.key"
                :value="mode.key"
              >
                {{ mode.label }}
              </option>
            </select>
            <select
              v-model="isAnswered"
              class="form-control mb-2"
              @change="loadQuestions"
            >
              <option value="" selected>Todas as questões</option>
              <option
                v-for="isAnswered in [
                  {
                    key: false,
                    label: 'Apenas não resolvidas',
                  },
                  {
                    key: true,
                    label: 'Apenas resolvidas',
                  },
                ]"
                :key="isAnswered.key"
                :value="isAnswered.key"
              >
                {{ isAnswered.label }}
              </option>
            </select>
          </template>

          <template v-else>
            <select
              v-model="sources"
              class="form-control mb-2"
              @change="loadQuestions"
            >
              <option value="" selected>Todas as origens</option>
              <option
                v-for="source in formOptions.source"
                :key="source"
                :value="source"
              >
                {{ source }}
              </option>
            </select>
          </template>
        </div>
      </div>
      <div class="col-lg-9 col-md-8 col-12">
        <div v-for="question in questions" :key="question.id">
          <Question :question="question" :showAccessButton="true" />
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="pagination.total"
          :per-page="pagination.perPage"
          prev-text="Anterior"
          next-text="Próxima"
          align="right"
        ></b-pagination>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";

import { mapState } from "vuex";

import * as api from "@/api";

import Question from "@/components/Question";

export default {
  page: {
    title: "Perguntas",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Layout,
    Question,
  },

  data() {
    return {
      instanceId: null,
      currentPage: 1,
      interests: [],
      onlyMyInterests: false,
      selectedInterestId: "",
      subjectId: "",
      roles: "",
      institutes: "",
      years: "",
      juries: "",
      disciplines: "",
      mode: "",
      isAnswered: false,
      search: "",
      sources: "",
      formOptions: {
        role: [],
        year: [],
        institute: [],
        jury: [],
        discipline: [],
        source: [],
      },
      pagination: {
        total: 0,
        perPage: 20,
        page: 1,
        lastPage: 1,
        items: [],
      },

      questions: [],
    };
  },

  computed: {
    ...mapState("auth", ["currentUser"]),

    isReport() {
      return this.$route.meta.isReport;
    },
  },

  created() {
    this.start();
  },

  methods: {
    start() {
      this.instanceId = Math.random()

      if (!this.currentUser.interests.length) {
        this.selectedInterestId = ""; // all
      } else {
        this.onlyMyInterests = true;
      }

      if (
        this.$route.query.interestId &&
        Number.isFinite(+this.$route.query.interestId)
      ) {
        this.onlyMyInterests = false;
        this.selectedInterestId = +this.$route.query.interestId;
      }

      this.loadQuestions();
      api
        .getAllQuestionFormOptions(!!this.isReport)
        .then((formOptions) => {
          this.formOptions = formOptions;
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao carregar opcões de pesquisa!", "error");
        });

      api
        .getAllInterests()
        .then((interests) => {
          this.interests = interests;
        })
        .catch(() => {
          this.$swal(
            "Opa",
            "Falha ao carregar interesses de pesquisa!",
            "error"
          );
        });
    },

    viewQuestion(question) {
      return this.$router.push({
        name: "viewQuestion",
        params: {
          id: question.id,
        },
      });
    },

    loadQuestions() {
      const {instanceId} = this
      window.scrollTo(0, 0);

      const interestId = this.onlyMyInterests
        ? "mine"
        : this.selectedInterestId;

      this.questions = [];
      const params = {
        page: this.currentPage,
        interestId: interestId ? interestId : undefined,
        subjectId: this.subjectId ? this.subjectId : undefined,
        year: this.years ? +this.years : undefined,
        role: this.roles ? this.roles : undefined,
        institute: this.institutes ? this.institutes : undefined,
        jury: this.juries ? this.juries : undefined,
        discipline: this.disciplines ? this.disciplines : undefined,
        source: this.sources ? this.sourcess : undefined,
        mode: this.mode ? this.mode : undefined,
        isAnswered:
          typeof this.isAnswered === "boolean" ? this.isAnswered : undefined,
        search: this.search ? this.search : undefined,
        limit: 10,
        isReport: !!this.isReport,
      };

      return api
        .getQuestions(params)
        .then((pagination) => {
          if (this.instanceId != instanceId) {
            return
          }
          this.pagination = pagination;
          this.totalRows = pagination.total;
          this.questions = pagination.items;
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao carregar perguntas!", "error");
          return [];
        });
    },
  },

  watch: {
    onlyMyInterests: "loadQuestions",

    currentPage() {
      this.loadQuestions();
    },

    "$route.name": "start",
  },
};
</script>

<style lang="scss">
.multiselect__select:before {
  top: 50% !important;
}

.multiselect__tags {
  padding: 8px 40px 0 8px !important;
}
</style>
